"use client";
import { AllPricingItems } from "@/lib/server/pricing/types";
import { create } from "zustand";
import { PricingStoreType } from "./pricingStore";
import axios from "axios";

export interface PricingItemsStoreType {
  pricingItems: AllPricingItems | undefined;
  setPricingItems: (pricingItems: AllPricingItems | undefined) => void;
}

export const usePricingItemsStore = create<PricingItemsStoreType>((set) => ({
  pricingItems: undefined,
  setPricingItems: (pricingItems) => set({ pricingItems }),
}));

export const fetchPricingItems = async (pricingStore: PricingStoreType) => {
  try {
    if (!pricingStore.plans)
      throw new Error("No plans found for fetching pricing items");
    console.log("fetching pricing items into state");

    const response = await axios.get("/api/pricing/pricing_items");
    const pricingItemsSought = response.data;

    return { pricingItems: pricingItemsSought };
  } catch (err) {
    console.error("Could not fetch pricing items");
    return { pricingItems: null };
  }
};
