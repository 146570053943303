"use client";
import PriceCard from "./PriceCard";
import { PlanCardType } from "../types/types";
import { getPlanCss } from "./utils";
import PlanBase from "./PlanBase";
import { AllPricingItems } from "@/lib/server/pricing/types";
import { PossiblePlansNoFree } from "@/lib/shared/pricing/utils";
import { PricingStoreType } from "../../stores/pricingStore";

const DiamondPlanCard = ({
  activeSubscriptionPlan,
  billing,
  pricingItems,
  user,
}: PlanCardType) => {
  const planType = billing === "monthly" ? "diamondPlan" : "diamondPlanYearly";
  return (
    <PlanBase
      user={user}
      activeSubscriptionPlan={activeSubscriptionPlan}
      planType={planType}
      billing={billing}
      pricingItems={pricingItems}
    />
  );
};

const DiamondPlanViewOnly = ({
  pricingItems,
  activeSbPlan,
  pricingStore,
}: {
  pricingItems: AllPricingItems;
  activeSbPlan: PlanCardType["activeSubscriptionPlan"] | undefined;
  pricingStore: PricingStoreType;
}) => {
  const diamondPricingItem = pricingItems["diamondPlan"];

  if (!diamondPricingItem) return null;
  const planCss = getPlanCss("diamondPlan");

  if (!pricingStore.plans) return null;
  const allPlans = pricingStore.plans;

  const sbPlan = {
    ["diamondPlan"]: allPlans["diamondPlan"],
  } as PossiblePlansNoFree;

  if (!activeSbPlan) return null;

  return (
    <PriceCard
      planCss={planCss}
      isAdmin={false} //no need to show edit overlay here
      wrapperForPlanBannerText=""
      shouldShowBanner={false}
      planTitle={diamondPricingItem.plan}
      tagline={diamondPricingItem.tagline}
      features={diamondPricingItem.features}
      footer={<></>}
      isComingSoon={diamondPricingItem.comingSoon}
      sbPlan={sbPlan}
      activeSbPlan={activeSbPlan}
    />
  );
};

export { DiamondPlanCard, DiamondPlanViewOnly };
