"use client";

import { AllPricingItems } from "@/lib/server/pricing/types";
import { PlanCardType } from "../types/types";
import PlanBase from "./PlanBase";
import PriceCard from "./PriceCard";
import { getPlanCss } from "./utils";
import { PricingStoreType, usePricingStore } from "../../stores/pricingStore";
import { PossiblePlansNoFree } from "@/lib/shared/pricing/utils";

const GoldPlanCard = ({
  activeSubscriptionPlan,
  billing,
  pricingItems,
  user,
}: PlanCardType) => {
  const planType = billing === "monthly" ? "goldPlan" : "goldPlanYearly";
  return (
    <PlanBase
      user={user}
      activeSubscriptionPlan={activeSubscriptionPlan}
      planType={planType}
      billing={billing}
      pricingItems={pricingItems}
    />
  );
};

const GoldPlanViewOnly = ({
  pricingItems,
  activeSbPlan,
  pricingStore,
}: {
  pricingItems: AllPricingItems;
  activeSbPlan: PlanCardType["activeSubscriptionPlan"] | undefined;
  pricingStore: PricingStoreType;
}) => {
  const goldPricingItem = pricingItems["goldPlan"];

  if (!goldPricingItem) return null;
  const planCss = getPlanCss("goldPlan");

  if (!pricingStore.plans) return null;
  const allPlans = pricingStore.plans;

  const sbPlan = {
    ["goldPlan"]: allPlans["goldPlan"],
  } as PossiblePlansNoFree;

  if (!activeSbPlan) return null;

  return (
    <PriceCard
      planCss={planCss}
      isAdmin={false} //no need to show edit overlay here
      wrapperForPlanBannerText=""
      shouldShowBanner={false}
      planTitle={goldPricingItem.plan}
      tagline={goldPricingItem.tagline}
      features={goldPricingItem.features}
      footer={<></>}
      isComingSoon={goldPricingItem.comingSoon}
      sbPlan={sbPlan}
      activeSbPlan={activeSbPlan}
    />
  );
};

export { GoldPlanCard, GoldPlanViewOnly };
