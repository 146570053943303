import { PLANS } from "@/config/plans";
import { StripePrice, StripeProduct } from "@/prisma/schema/mysql";

export type AllPlans = typeof PLANS;
export type AllPlansNoFree = Omit<AllPlans, "freePlan">;

export type PossiblePlans = {
  [K in keyof typeof PLANS]: { [P in K]: (typeof PLANS)[K] };
}[keyof typeof PLANS];

export type PossiblePlansNoFree = {
  [K in keyof AllPlansNoFree]: { [P in K]: AllPlansNoFree[K] };
}[keyof AllPlansNoFree];

export type PossiblePlansValues = (typeof PLANS)[keyof typeof PLANS];
export type PossiblePlanKeys = keyof typeof PLANS;
export type PossiblePlanKeysNoFree = Exclude<PossiblePlanKeys, "freePlan">;
/**
 * Takes a product from the DB and remodels it to the shape of a singular plan defined as key-value in PLANS.
 * @param str DB plan joined with price
 * @returns for example "{ goldPlan: {...} }"
 */
export const BridgeBetweenDbProductAndAppProduct = (
  str: StripeProduct & { prices: StripePrice[] },
): PossiblePlans | null => {
  let obj: PossiblePlans | null = null;
  Object.entries(PLANS).forEach(([key, value]) => {
    if (
      value.remoteMetadataName === str.remoteMetadataName &&
      value.billing === str.billing
    ) {
      const objWithCorrectShape = str;
      obj = {
        [key]: objWithCorrectShape as AllPlans[keyof AllPlans],
      } as PossiblePlans;
    }
  });
  if (!obj) return null;
  return obj as PossiblePlans;
};

/**
 * Checks if an object is of the shape of the PLANS object.
 * @param obj object to check
 * @returns true if the object is of the shape of the PLANS object, false otherwise
 */
export const objectIsWholePriceObject = (
  obj: any,
): obj is Omit<typeof PLANS, "freePlan"> => {
  Object.keys(PLANS)
    .filter((key) => key !== "freePlan")
    .forEach((key) => {
      if (!obj || !(key in obj)) {
        return false;
      }
    });
  return true;
};

/**
 * Creates an object from DB plans with the shape of the PLANS object that contains the defaults.
 * @param plans array with all DB plans joined with their prices
 * @returns an object with the shape of PLANS object, containing all gold - platinum - diamond plans and their yearly
 */
export const createWholePriceObjectThrowsErr = (
  plans: Array<StripeProduct & { prices: StripePrice[] }>,
): AllPlans => {
  let plansCoerced = plans.reduce((acc, product) => {
    const plan = BridgeBetweenDbProductAndAppProduct(product);
    if (!plan) return acc;
    return {
      ...acc,
      ...plan,
    };
  }, {}) as Partial<typeof PLANS>;
  plansCoerced["freePlan"] = PLANS.freePlan;
  if (!objectIsWholePriceObject(plansCoerced))
    throw new Error("Plans object is not valid");
  return plansCoerced as AllPlans;
};

export const extractPlanValue = (
  appPlan: NonNullable<PossiblePlans>,
): PossiblePlansValues => {
  return Object.entries(appPlan)[0][1];
};

export const extractPlanKey = (
  appPlan: NonNullable<PossiblePlans>,
): PossiblePlanKeys => {
  return Object.entries(appPlan)[0][0] as PossiblePlanKeys;
};

/**
 * Throws an error if the plan object has multiple default prices or no default at all
 * @param plan plan to find the default price of
 * @returns the default price of the plan
 */
export const findDefaultPriceOfPlan = (plan: NonNullable<PossiblePlans>) => {
  const planValue = extractPlanValue(plan);
  const defaults = planValue!.prices.filter((price) => price.default === true);
  if (defaults.length !== 1) return undefined;
  return defaults[0];
};

export const findOriginalPriceOfPlan = (plan: NonNullable<PossiblePlans>) => {
  const planValue = extractPlanValue(plan);
  const original = planValue!.prices.filter((price) => price.original);
  if (original.length !== 1) return undefined;
  return original[0];
};
