import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Form,
  toast,
} from "@/components/ui";
import { PricingItemContent } from "@/lib/server/pricing/types";
import { PossiblePlansValues } from "@/lib/shared/pricing/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { planEdit, PlanEditFormType } from "../types/types";
import {
  fetchPricingItems,
  usePricingItemsStore,
} from "../../stores/pricingItemsStore";
import { usePricingStore } from "../../stores/pricingStore";
import axios from "axios";

export default function PlanEdit({
  isOpen,
  setIsOpen,
  pricingItem,
  plan,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricingItem: PricingItemContent;
  plan: PossiblePlansValues;
}) {
  const pricingStore = usePricingStore();
  const pricingItemsStore = usePricingItemsStore();

  const form = useForm<PlanEditFormType>({
    resolver: zodResolver(planEdit),
    defaultValues: {
      tagline: "",
      features: [],
      name: "",
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = form;

  const resetForm = () => {
    if (!pricingItem) return;
    reset({
      tagline: pricingItem.tagline,
      features: pricingItem.features,
      name: pricingItem.plan,
    });
  };

  useEffect(() => {
    if (!pricingItem) return;
    resetForm();
  }, [pricingItem, reset]);

  const features = watch("features");

  const onSubmit = async (values: PlanEditFormType) => {
    try {
      await axios.put(`/api/pricing/plans`, {
        plan: values,
        billing: plan.billing,
        remoteMetadataName: plan.remoteMetadataName,
      });
      toast({
        duration: 4000,
        description: "Plan updated successfully",
      });
      const { pricingItems } = await fetchPricingItems(pricingStore);
      pricingItemsStore.setPricingItems(pricingItems);
    } catch (e) {
      console.error(e);
      toast({
        duration: 4000,
        variant: "destructive",
        description: "Could not update plan",
      });
    }
    setIsOpen(false);
  };

  const addFeature = () => {
    setValue("features", [...features, { footnote: "", text: "" }]);
  };

  const deleteFeature = (index: number) => {
    setValue(
      "features",
      features.filter((_, i) => i !== index),
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex text-2xl justify-center items-center flex-col gap-y-4 pb-2">
            Change product details
          </DialogTitle>
          <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="shadcn"
                          {...field}
                          className="p-1"
                        />
                      </FormControl>
                      <FormDescription>The plan's display name</FormDescription>
                      <FormMessage>{errors.name?.message}</FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="tagline"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tagline</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="shadcn"
                          {...field}
                          className="p-1"
                        />
                      </FormControl>
                      <FormDescription>Plan's description</FormDescription>
                      <FormMessage>{errors.tagline?.message}</FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="features"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Features</FormLabel>
                      <FormControl>
                        <div className="max-h-64 overflow-y-auto">
                          {field.value.map((feature, index) => (
                            <div
                              key={index}
                              className="flex flex-col gap-y-2 mb-4 p-4 border rounded-lg"
                            >
                              <div className="flex items-center gap-x-2 mb-2">
                                <FormField
                                  control={control}
                                  name={`features.${index}.text`}
                                  render={({ field }) => (
                                    <FormItem className="flex-1">
                                      <FormLabel>
                                        Feature {index + 1} text
                                      </FormLabel>
                                      <FormControl>
                                        <Input {...field} className="p-1" />
                                      </FormControl>
                                      <FormMessage>
                                        {
                                          errors.features?.[index]?.text
                                            ?.message
                                        }
                                      </FormMessage>
                                    </FormItem>
                                  )}
                                />
                                <button
                                  type="button"
                                  onClick={() => deleteFeature(index)}
                                  className="text-red-500 bg-red-100 hover:bg-red-200 px-2 py-1 rounded"
                                >
                                  Delete
                                </button>
                              </div>
                              <FormField
                                control={control}
                                name={`features.${index}.footnote`}
                                render={({ field }) => (
                                  <FormItem className="flex-1">
                                    <FormLabel>Footnote</FormLabel>
                                    <FormControl>
                                      <Input {...field} className="p-1" />
                                    </FormControl>
                                    <FormMessage>
                                      {
                                        errors.features?.[index]?.footnote
                                          ?.message
                                      }
                                    </FormMessage>
                                  </FormItem>
                                )}
                              />
                            </div>
                          ))}
                        </div>
                      </FormControl>
                      <FormMessage>{errors.features?.message}</FormMessage>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex ml-auto w-max gap-2 ">
                <button
                  type="button"
                  onClick={() => {
                    resetForm();
                  }}
                  className="mt-4 text-white-500 bg-red-500 hover:bg-red-600 px-4 py-2 rounded"
                >
                  Reset Form
                </button>
                <button
                  type="button"
                  onClick={addFeature}
                  className="mt-4 text-blue-500 bg-blue-100 hover:bg-blue-200 px-4 py-2 rounded"
                >
                  Add Feature
                </button>
                <button
                  type="submit"
                  className="flex bg-black text-white mt-4 px-4 py-2 rounded ml-auto"
                >
                  Submit
                </button>
              </div>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
