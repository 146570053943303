"use client";
import PriceCard from "./PriceCard";
import { PlanCardType } from "../types/types";
import PlanBase from "./PlanBase";
import { AllPricingItems } from "@/lib/server/pricing/types";
import { PricingStoreType } from "../../stores/pricingStore";
import { getPlanCss } from "./utils";
import { PossiblePlansNoFree } from "@/lib/shared/pricing/utils";

const PlatinumPlanCard = ({
  activeSubscriptionPlan,
  billing,
  // pricingStore,
  pricingItems,
  user,
}: PlanCardType) => {
  const planType =
    billing === "monthly" ? "platinumPlan" : "platinumPlanYearly";
  return (
    <PlanBase
      activeSubscriptionPlan={activeSubscriptionPlan}
      planType={planType}
      billing={billing}
      pricingItems={pricingItems}
      user={user}
    />
  );
};

const PlatinumPlanViewOnly = ({
  pricingItems,
  activeSbPlan,
  pricingStore,
}: {
  pricingItems: AllPricingItems;
  activeSbPlan: PlanCardType["activeSubscriptionPlan"] | undefined;
  pricingStore: PricingStoreType;
}) => {
  const platinumPricingItem = pricingItems["platinumPlan"];

  if (!platinumPricingItem) return null;
  const planCss = getPlanCss("platinumPlan");

  if (!pricingStore.plans) return null;
  const allPlans = pricingStore.plans;

  const sbPlan = {
    ["platinumPlan"]: allPlans["platinumPlan"],
  } as PossiblePlansNoFree;

  if (!activeSbPlan) return null;

  return (
    <PriceCard
      planCss={planCss}
      isAdmin={false} //no need to show edit overlay here
      wrapperForPlanBannerText=""
      shouldShowBanner={false}
      planTitle={platinumPricingItem.plan}
      tagline={platinumPricingItem.tagline}
      features={platinumPricingItem.features}
      footer={<></>}
      isComingSoon={platinumPricingItem.comingSoon}
      sbPlan={sbPlan}
      activeSbPlan={activeSbPlan}
    />
  );
};

export { PlatinumPlanCard, PlatinumPlanViewOnly };
