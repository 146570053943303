import { getUserSubscriptionPlan } from "@/lib/server/pricing/stripe";
import { PricingStoreType } from "../../stores/pricingStore";
import { AllPricingItems } from "@/lib/server/pricing/types";
import { z } from "zod";

export type PlanCardType = {
  activeSubscriptionPlan: Awaited<ReturnType<typeof getUserSubscriptionPlan>>;
  billing: "monthly" | "yearly";
  isViewOnly?: boolean;
  user?: boolean;
  // pricingStore: PricingStoreType;
  pricingItems?: AllPricingItems;
};

export type PlanEditFormType = {
  tagline: string;
  features: { text: string; footnote: string }[];
  name: string;
};

export const planEdit = z.object({
  tagline: z.string().min(1, "Tagline is required"),
  features: z
    .array(
      z.object({
        text: z.string().min(1, "Feature text is required"),
        footnote: z.string().min(1, "Feature footnote is required"),
      }),
    )
    .min(1, "At least one feature is required")
    .max(5, "Maximum of 5 features allowed"),
  name: z.string().min(1, "Name is required"),
});

export type PlanEditInferred = z.infer<typeof planEdit>;
