"use client";
import {
  AllPlans,
  createWholePriceObjectThrowsErr,
  PossiblePlans,
} from "@/lib/shared/pricing/utils";
import {
  BillingFrequency,
  StripePrice,
  StripeProduct,
} from "@/prisma/schema/mysql";
import axios from "axios";
import { create } from "zustand";

export interface PricingStoreType {
  plans: AllPlans | null;
  setPlans: (plans: AllPlans | null) => void;
  setPlan: ({
    remoteMetadataName,
    billing,
    plan,
  }: {
    remoteMetadataName: string;
    billing: BillingFrequency;
    plan: PossiblePlans;
  }) => void;
}

export const usePricingStore = create<PricingStoreType>((set) => ({
  plans: null,
  setPlans: (plans) => set({ plans }),
  //remoteMetadataName and billing can identify a plan
  setPlan: ({ remoteMetadataName, billing, plan }) => {
    set((state) => {
      if (!state.plans)
        return {
          plans: null,
        };
      let keyToUpdate: null | keyof AllPlans = null;
      Object.entries(state.plans).map(([key, value]) => {
        if (key === remoteMetadataName && value.billing === billing) {
          keyToUpdate = key as keyof AllPlans;
        }
      });
      if (!keyToUpdate) return state;
      return {
        plans: {
          ...state.plans,
          plan,
        },
      };
    });
  },
}));

export const fetchPlans = async () => {
  let priceObj = null;
  try {
    const res =
      await axios.get<Array<StripeProduct & { prices: StripePrice[] }>>(
        "/api/pricing/all",
      );
    priceObj = createWholePriceObjectThrowsErr(res.data);
  } catch (err) {
    console.log(err);
  }

  return {
    plans: priceObj,
  };
};
