import clsx from "clsx";
import { PlanCardType } from "../types/types";
import UpgradeButton from "./UpgradeButton";
import { getUserSubscriptionPlan } from "@/lib/server/pricing/stripe";
import {
  AllPlans,
  AllPlansNoFree,
  extractPlanValue,
  findDefaultPriceOfPlan,
  findOriginalPriceOfPlan,
  PossiblePlanKeys,
  PossiblePlanKeysNoFree,
  PossiblePlans,
  PossiblePlansNoFree,
} from "@/lib/shared/pricing/utils";
import "./styles.css";
import { PricingStoreType } from "../../stores/pricingStore";

export const isAbleToChangePlanFrontend = (
  fromPlan: Awaited<ReturnType<typeof getUserSubscriptionPlan>>,
  toPlan: PossiblePlanKeys,
  allPlans: AllPlans,
): boolean => {
  if (fromPlan.activePlan.billing === "yearly") {
    if (allPlans[toPlan].billing === "yearly" && fromPlan.planType === toPlan)
      return true;
    return false;
  }
  return true;
};

const planStylesBase = {
  freePlan: {
    textStyle: "",
    upgBtnStyle: "",
    bannerActive: "",
    bannerUpgrade: "",
  },
  goldPlan: {
    textStyle: "text-amber-500",
    upgBtnStyle: "bg-amber-500 text-black hover:bg-amber-600",
    bannerActive: "rounded-2xl bg-green-300 px-3 py-2 text-sm font-medium",
    bannerUpgrade: "rounded-2xl bg-amber-500 px-3 py-2 text-sm font-medium",
  },
  platinumPlan: {
    textStyle: "text-green-500",
    upgBtnStyle: "bg-green-500 text-black hover:bg-green-600",
    bannerActive: "rounded-2xl bg-lime-400 px-3 py-2 text-sm font-medium",
    bannerUpgrade: "rounded-2xl bg-green-500 px-3 py-2 text-sm font-medium",
  },
  diamondPlan: {
    textStyle: "text-blue-500",
    upgBtnStyle: "bg-blue-500 text-white hover:bg-blue-600",
    bannerActive: "rounded-2xl bg-green-200 px-3 py-2 text-sm font-medium",
    bannerUpgrade:
      "rounded-2xl bg-blue-500 px-3 py-2 text-sm font-medium text-white",
  },
};

const planStyles = {
  freePlan: planStylesBase.freePlan,
  goldPlan: planStylesBase.goldPlan,
  diamondPlan: planStylesBase.diamondPlan,
  platinumPlan: planStylesBase.platinumPlan,
  goldPlanYearly: planStylesBase.goldPlan,
  diamondPlanYearly: planStylesBase.diamondPlan,
  platinumPlanYearly: planStylesBase.platinumPlan,
};

export const getPlanCss = (arg: PossiblePlanKeys) => {
  return planStyles[arg];
};

export const shouldShowTrialingDetails = (
  activeSubscriptionPlan: PlanCardType["activeSubscriptionPlan"],
  subscriptionPlanType: PossiblePlanKeys,
) => {
  return (
    !activeSubscriptionPlan.hasSubscribedAtLeastOnce ||
    (activeSubscriptionPlan.stripeSubscriptionStatus === "trialing" &&
      activeSubscriptionPlan.planType === subscriptionPlanType)
  );
};

export const renderCancellationNotice = ({
  activeSubscriptionPlan,
  isSubscribedToThisPlan,
}: {
  activeSubscriptionPlan: PlanCardType["activeSubscriptionPlan"];
  isSubscribedToThisPlan: boolean;
}) => {
  return (
    <>
      {activeSubscriptionPlan.isCanceled &&
        activeSubscriptionPlan.stripeCurrentPeriodEnd &&
        isSubscribedToThisPlan && (
          <div className="w-full absolute shadow-lg top-[100%] bg-black text-white rounded-b-lg">
            <p className="text-center w-full">
              Cancels at{" "}
              {activeSubscriptionPlan.stripeCurrentPeriodEnd.toDateString()}
            </p>
          </div>
        )}
    </>
  );
};

export const getWrapperForPlanBannerStyle = ({
  baseWrapperForPlanBannerStyle,
  isUpgradeOrDowngrade,
  isSubscribedToThisPlan,
  planType,
  discount,
  isComingSoon = false,
}: {
  baseWrapperForPlanBannerStyle: string;
  isUpgradeOrDowngrade: boolean;
  isSubscribedToThisPlan: boolean;
  planType: PossiblePlanKeys;
  discount?: number;
  isComingSoon?: boolean;
}) => {
  const css = getPlanCss(planType);
  if (isComingSoon) {
    return clsx(
      baseWrapperForPlanBannerStyle,
      "px-4 py-3 text-sm font-medium bg-gradient-to-r from-purple-600 to-blue-400",
    );
  }
  if (isSubscribedToThisPlan)
    return clsx(baseWrapperForPlanBannerStyle, css.bannerActive);

  if (discount && discount > 0)
    return clsx(
      baseWrapperForPlanBannerStyle,
      "px-3 py-2 bg-gradient-to-r from-orange-700 via-orange-500 to-yellow-300",
    );

  if (isUpgradeOrDowngrade) return "hidden";

  return clsx(baseWrapperForPlanBannerStyle, css.bannerUpgrade);
};

export const getLogicForInteractivity = ({
  activeSubPlan,
  planSubscribingToType,
  allPlans,
}: {
  activeSubPlan: PlanCardType["activeSubscriptionPlan"];
  planSubscribingToType: PossiblePlanKeys;
  allPlans: AllPlans;
}) => {
  const isSubscribedToThisPlan =
    activeSubPlan.planType === planSubscribingToType;
  const isUpgradeOrDowngrade =
    activeSubPlan.isSubscribed && !isSubscribedToThisPlan;
  const freeTrialAvailable = !activeSubPlan.hasSubscribedAtLeastOnce;
  const activeBilling: "monthly" | "yearly" | false =
    activeSubPlan.activePlan.billing ?? false;
  const isDisabledUpgradeButton = !isAbleToChangePlanFrontend(
    activeSubPlan,
    planSubscribingToType,
    allPlans,
  );

  return {
    isSubscribedToThisPlan,
    isUpgradeOrDowngrade,
    isDisabledUpgradeButton,
    activeBilling,
    freeTrialAvailable,
  };
};

export const getStyles = ({
  isSubscribedToThisPlan,
  isDisabledUpgradeButton,
  activeBilling,
  freeTrialAvailable,
  planType,
  pricingStore,
  isComingSoon = false,
  user,
}: ReturnType<typeof getLogicForInteractivity> & {
  planType: PossiblePlanKeysNoFree;
  pricingStore: PricingStoreType;
  isComingSoon?: boolean;
  user?: boolean;
}) => {
  const planStyles = getPlanCss(planType);
  if (!pricingStore.plans) return null;
  const planToUpgradeTo = pricingStore.plans[planType];

  const renderFooter = () => {
    return (
      <UpgradeButton
        activeBilling={activeBilling}
        isDisabled={isDisabledUpgradeButton}
        isSubscribedToThisPlan={isSubscribedToThisPlan}
        freeTrialAvailable={freeTrialAvailable}
        planToUpgradeTo={planToUpgradeTo}
        buttonStyle={planStyles.upgBtnStyle}
        pricingStore={pricingStore}
        isComingSoon={isComingSoon}
        user={user}
      />
    );
  };

  return { renderFooter };
};
